@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.colorfull_Heading{
	color: #f92c8b;
	text-transform: uppercase;
	transition: all 0.3s ease;
}

body{
	font-family: "Poppins", system-ui;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: black;
  color: white;
}

.backgroundImage{
  background-image: url(../src/images/backgroundImage.png);
  height: 100%;
  width: 100%;
  background-size: cover;
  
}

.blacktext{
	color: black;
}
.heading{
	font-size: 35px;
	line-height: 45px
}

.tick{
	color: green;
}
.list-content{
	line-height: 2;
}

.heading-voicecall-card{
	font-size: 30px;
	font-weight: 600;
}

.footerIcon{
	color: #ff1f8e !important;
}
@media only screen and (max-width: 768px) {
	.heading{
		font-size: 25px;
		line-height: 30px
	}
  p{
	font-size: 15px;
  }
  }
  

  
.loader-line {
	width: 200px;
	height: 3px;
	position: relative;
	overflow: hidden;
	background-color: transparent;
	margin: 10px auto;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
  }
  
  .loader-line:before {
	content: "";
	position: absolute;
	left: -50%;
	height: 3px;
	width: 40%;
	background-color: #ff1f8e;
	-webkit-animation: lineAnim 1s linear infinite;
	-moz-animation: lineAnim 1s linear infinite;
	animation: lineAnim 1s linear infinite;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
  }
  
  @keyframes lineAnim {
	0% {
	  left: -40%;
	}
	50% {
	  left: 20%;
	  width: 80%;
	}
	100% {
	  left: 100%;
	  width: 100%;
	}
  }
  